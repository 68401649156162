/* You can add global styles to this file, and also import other style files */
@import "./app/theme/scss/variables.scss";
@import "./app/theme/scss/classes.scss";
@import "./app/theme/scss/site.css";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../node_modules/bootstrap/dist/css/bootstrap-grid.min.css";
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeng/resources/themes/rhea/theme.css";
@import "../node_modules/leaflet/dist/leaflet.css";
@import "../node_modules/startbootstrap-sb-admin-2/css/sb-admin-2.min.css"